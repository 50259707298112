@keyframes root-heading--appear--layout {
	from {
		width: 250px;
	}
	to {
		width: 1000px;
	}
}

@keyframes root-heading--appear--links {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes root-heading--appear--logo {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

#root-header {
	padding: 50px 50px;
	height: 213px;
	width: 100%;
	box-sizing: border-box;
	color: #C1974D;
	z-index: 2;

	nav {
		display: flex;
		width: 250px;
		max-width: 100%;
		height: 100%;
		margin: 0 auto;
		justify-items: center;
		align-items: center;
		
		@media screen and (min-width: 801px) {
			animation: root-heading--appear--layout .7s .3s forwards;
		}

		.left, .right {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex: 1;
			overflow: hidden;
		}

		a {
			color: currentColor;
			text-decoration: none;
			white-space: nowrap;
			display: inline-flex;
			max-width: 100%;
			overflow: hidden;

			&.current {
				text-decoration: underline;
			}

			&.logo-link {
				overflow: visible;
				min-width: 90px;
				
				@media screen and (min-width: 801px) {
					margin: 0 80px;
					height: 100%;
					justify-content: center;
				}
			}

			&:not(.logo-link) {
				opacity: 0;
				animation: root-heading--appear--links .7s .3s forwards;

				@media screen and (max-width: 800px) {
					display: none;
				}
			}

			.logo {
				margin: 0 10px;
				height: 100%;
				width: 100%;
				opacity: 0;
				animation: root-heading--appear--logo .3s forwards;
			}

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

#scrolling-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10;
	box-sizing: border-box;
	display: grid;
	overflow: hidden;
	align-items: center;
	justify-content: space-between;
	grid-template-columns: max-content 1fr max-content;
	grid-template-rows: 114px calc(100% - 114px);
	pointer-events: none;
	color: #C1974D;
	opacity: 0;
	height: 0px;
	padding: 0 50px;
	grid-template-areas:
	"a . b"
	"c c c";
	transition: height .5s, padding .5s, opacity .5s, color .3s;

	@media screen and (max-width: 800px) {
		opacity: 1;
		padding: 50px 36px;
		height: 214px;

		.logo, .toggle {
			pointer-events: all;
			align-self: flex-start;
			overflow: visible;
		}
	}

	&.visible {
		opacity: 1;
		padding: 50px 50px;
		height: 214px;
		color: #C1974D !important;

		.logo, .toggle {
			pointer-events: all;
		}

		@media screen and (max-width: 800px) {
			padding: 50px 36px;
		}
	}

	&.open {
		height: 100vh;
		pointer-events: all;

		.background {
			opacity: 1;
			pointer-events: all;

			img {
				transform: translate(0, 0%);
			}
		}

		.toggle {
			span {
				background-color: #fff;
			}

			span:nth-child(1) {
				transform: translate(0px, 10px) rotate(45deg);
			}

			span:nth-child(2) {
				opacity: 0;
			}

			span:nth-child(3) {
				transform: translate(0px, 10px) rotate(-45deg);
			}
		}

		.menu {
			opacity: 1;
			padding-top: 75px;

			@media screen and (max-width: 1300px) {
				opacity: 1;
				padding-top: 0px;
			}

			nav.primary {
				.group {
					.child {
						@keyframes secondary-nav-link {
							from {
								opacity: 0;
								transform: translateY(-39px);
							}
							to {
								opacity: 1;
								transform: none;
							}
						}

						opacity: 0;
						transform: translateY(-39px);
						animation: secondary-nav-link forwards .3s calc(var(--primary-index) * .2s + var(--secondary-index) * .1s);
					}
				}

				a.page {
					@keyframes primary-nav-link {
						from {
							opacity: 0;
							transform: rotateY(-45deg);
						}
						to {
							opacity: 1;
							transform: rotateY(0deg);
						}
					}

					opacity: 0;
					transform: rotateY(-45deg);
					animation: primary-nav-link forwards .5s calc(var(--primary-index) * .2s);
				}
			}
		}

		.social {
			@keyframes social-links {
				from {
					opacity: 0;
					transform: rotateY(45deg);
				}
				to {
					opacity: 1;
					transform: none;
				}
			}

			opacity: 0;
			transform: rotateX(45deg);
			animation: social-links forwards 1s;
		}
	}

	.background {
		position: absolute;
		pointer-events: none;
		opacity: 0;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: opacity .5s, clip-path .5s;

		img {
			width: 100%;
			height: 100vh;
			transform: translate(0, -50%);
			object-fit: cover;
			transition: transform .5s;
		}

		&::after {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			background-color: rgba(25, 25, 25, 0.73);
			content: "";
		}
	}

	.logo {
		grid-area: a;
		color: currentColor;

		@media screen and (max-width: 800px) {
			display: none;
		}
	}

	.toggle {
		grid-area: b;
		position: relative;
		display: inline-flex;
		width: 30px;
		height: 22px;
		cursor: pointer;
		margin-right: 12px;
		z-index: 2;

		@media screen and (max-width: 800px) {
			margin-right: 0;
		}

		span {
			position: absolute;
			transform-origin: center;
			width: 100%;
			height: 2px;
			border-radius: 1px;
			top: 0;
			left: 0;
			background-color: currentColor;
			transition: transform .3s, opacity .3s, background-color .3s;

			&:nth-child(1) {
				transform: translate(0px, 0px);
			}

			&:nth-child(2) {
				transform: translate(0px, 10px);
			}

			&:nth-child(3) {
				transform: translate(0px, 20px);
			}
		}
	}

	.menu {
		grid-area: c;
		position: relative;
		overflow: hidden;
		display: flex;
		padding-top: 0;
		padding-left: 180px;
		box-sizing: border-box;
		height: 100%;
		width: 100%;
		opacity: 0;
		transition: padding .5s, opacity .5s;

		@media screen and (max-width: 1300px) {
			overflow-y: auto;
			z-index: 1;
			grid-area: unset;
			grid-column: 1 / 4;
			grid-row: 1 / 3;
			margin-top: 70px;
			padding-left: 0;
			flex-direction: column;
		}

		nav.primary {
			display: grid;
			width: 100%;
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: repeat(auto-fill, 71px);
			row-gap: 8px;
			column-gap: 16px;
			grid-auto-flow: column;

			@media screen and (max-width: 1300px) {
				grid-template-columns: 1fr;
				grid-template-rows: auto;
				height: max-content;
			}

			.single {
				grid-column: 1;
				white-space: nowrap;
			}

			.group {
				grid-row: 1 / -1;

				@media screen and (max-width: 1300px) {
					grid-column: 1;
					grid-row: unset;
					margin-top: 0px;
				}

				nav.secondary {
					max-width: 291px;
					margin-top: 22px;
					display: flex;
					flex-direction: column;

					@media screen and (max-width: 1300px) {
						display: none;
					}

					a {
						color: #C6C6C6;
						font-size: 18px;
						line-height: 21px;
						text-decoration: none;
						margin-right: auto;

						&:not(:last-child) {
							margin-bottom: 18px;

							@media screen and (max-width: 1300px) {
								margin-bottom: 2px;
							}
						}
					}
				}
			}

			a.page {
				display: inline-block;
				font-family: quincy;
				font-size: 56px;
				line-height: 71px;
				color: #fff;
				text-decoration: none;
				margin-right: auto;
				transform-origin: left;

				@media screen and (max-width: 1300px) {
					font-size: 36px;
					line-height: 42px;
				}
			}
		}

		.social {
			display: flex;
			position: relative;

			@media screen and (min-width: 1301px) {
				flex-direction: column;
				margin-left: 100px;
				box-sizing: border-box;
				width: 56px;
				padding: 13px;
				height: max-content;
				border-radius: 27px;
				border: 2px solid #fff;
				transform-origin: right;
			}

			@media screen and (max-width: 1300px) {
				margin-top: 50px;
				margin-bottom: 60px;
			}

			a {
				width: 26px;
				height: 26px;

				&:not(:last-child) {
					@media screen and (min-width: 1301px) {
						margin-bottom: 10px;
					}

					@media screen and (max-width: 1300px) {
						margin-right: 30px;
					}
				}

				svg {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
}

#footer {
	padding: 50px 50px;
	display: flex;
	flex-direction: column;
	width: 100%;
	box-sizing: border-box;
	background-color: #F2EFEB;

	@media screen and (max-width: 920px) {
		padding: 50px 16px;
	}

	nav {
		flex: 1;
		max-width: 1500px;
		margin: 0 auto;
		display: grid;
		grid-template-columns: repeat(var(--item-count), 1fr);
		justify-items: center;
		justify-content: space-between;
		align-items: center;

		@media screen and (max-width: 920px) {
			grid-template-columns: 1fr;
			height: unset;
			grid-template-rows: repeat(var(--item-count), auto);
			row-gap: 45px;
		}

		a {
			color: #C1974D;
			text-decoration: none;

			@media screen and (min-width: 921px) {
				margin-top: 64px;
			}

			&.logo {
				margin: 0 10px;
				
				@media screen and (max-width: 920px) {
					grid-row: 1;
				}

				img {
					height: 151px;
				}
			}
		}
	}

	.social {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 50px 0;

		a {
			color: #C1974D;
			height: 24px;
			display: inline-flex;

			@media screen and (max-width: 920px) {
				height: 30px;
			}

			&:not(:first-child) {
				margin-left: 15px;
			}

			&:not(:last-child) {
				margin-right: 15px;
			}

			svg {
				height: 100%;
				width: auto;
			}
		}
	}

	.content {
		text-align: center;
		line-height: 28px;

		a {
			color: #C1974D;
		}
	}
}