/**
 * Quincy
 */

// Quincy Regular
@font-face {
	font-family: quincy;
	font-weight: 400;
	src: url(/_resources/themes/goddard/webfonts/quincy/QuincyCF-Regular.woff2);
}

// Quincy Regular Italic
@font-face {
	font-family: quincy;
	font-weight: 400;
	font-style: italic;
	src: url(/_resources/themes/goddard/webfonts/quincy/QuincyCF-RegularItalic.woff2);
}

// Quincy Light
@font-face {
	font-family: quincy;
	font-weight: 300;
	src: url(/_resources/themes/goddard/webfonts/quincy/QuincyCF-Light.woff2);
}

// Quincy Light Italic
@font-face {
	font-family: quincy;
	font-weight: 300;
	font-style: italic;
	src: url(/_resources/themes/goddard/webfonts/quincy/QuincyCF-LightItalic.woff2);
}

// Quincy Medium
@font-face {
	font-family: quincy;
	font-weight: 600;
	src: url(/_resources/themes/goddard/webfonts/quincy/QuincyCF-Medium.woff2);
}

// Quincy Medium Italic
@font-face {
	font-family: quincy;
	font-weight: 600;
	font-style: italic;
	src: url(/_resources/themes/goddard/webfonts/quincy/QuincyCF-MediumItalic.woff2);
}

// Quincy Bold
@font-face {
	font-family: quincy;
	font-weight: 700;
	src: url(/_resources/themes/goddard/webfonts/quincy/QuincyCF-Bold.woff2);
}

// Quincy Bold Italic
@font-face {
	font-family: quincy;
	font-weight: 700;
	font-style: italic;
	src: url(/_resources/themes/goddard/webfonts/quincy/QuincyCF-BoldItalic.woff2);
}

/**
 * Inter
 */

// Inter
@font-face {
	font-family: inter;
	font-weight: normal;
	src: url(/_resources/themes/goddard/webfonts/inter/Inter-Regular.woff2);
}

// Inter Light
@font-face {
	font-family: inter;
	font-weight: 300;
	src: url(/_resources/themes/goddard/webfonts/inter/Inter-Light.woff2);
}

// Inter Medium
@font-face {
	font-family: inter;
	font-weight: 500;
	src: url(/_resources/themes/goddard/webfonts/inter/Inter-Medium.woff2);
}

// Inter SemiBold
@font-face {
	font-family: inter;
	font-weight: 600;
	src: url(/_resources/themes/goddard/webfonts/inter/Inter-SemiBold.woff2);
}

/**
 * Defaults
 */

body {
	font-family: inter, sans-serif;
	color: #473833;
}

/**
 * Include on content wrappers for universal fonts, which may be helpful to CMS users
 */
@mixin default-fonts {
	:first-child:not(.button) {
		margin-top: 0;
	}

	:last-child:not(.button) {
		margin-bottom: 0;
	}

	h6 {
		font-weight: 500;
		font-size: 16px;
		letter-spacing: 2px;
		text-transform: uppercase;

		@media screen and (max-width: 550px) {
			font-size: 14px;
		}
	}

	h1, h2, h3, h4, h5 {
		font-family: quincy, serif;
		font-weight: 300;
		color: #C1974D;
		margin: 0;
		margin-bottom: 16px;
	}

	h1 {
		font-size: 68px;
		line-height: 72px;
		font-weight: 400;

		@media screen and (max-width: 550px) {
			font-size: 54px;
			line-height: 53px;
		}
	}

	h2 {
		font-size: 57px;
	}

	h3 {
		font-size: 45px;
		line-height: 46px;
	}

	h4 {
		font-size: 30px;
		line-height: 38px;
		font-weight: 400;
	}

	p {
		line-height: 29px;

		@media screen and (max-width: 550px)  {
			font-size: 14px;
			line-height: 24px;
		}
	}

	a:not(.button) {
		color: #C1974D;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}